<template>
  <div>
    <Navigation />
    <div class="pa-5 flex space-between">
      <MaterialFilters />
      <ElButton @click="$refs.materialDialog.add()" type="primary"
        >Adauga Material</ElButton
      >
    </div>
    <!-- table -->
    <ResourceTable
      :query="query"
      :filter="filter"
      :default-sort="{ prop: 'computed.availability' }"
      :row-class-name="getRowClassName"
    >
      <ElTableColumn type="index" width="50" />
      <ElTableColumn prop="data.name" label="Nume" width="300" sortable>
        <template slot-scope="{ row }">{{ row.data.name  }} - #{{ row.id }}</template>
      </ElTableColumn>
      <ElTableColumn prop="data.fsc" label="FSC Mix" sortable width="100">
        <ElTag
          slot-scope="{ row }"
          v-if="row.data.fsc"
          size="mini"
          type="success"
          >FSC Mix</ElTag
        >
      </ElTableColumn>
      <ElTableColumn prop="data.price" label="Pret" width="100" sortable>
        <template slot-scope="{ row }">{{ row.data.price }} lei</template>
      </ElTableColumn>
      <ElTableColumn
        prop="computed.availability"
        label="Disponibilitate"
        sortable
        :sort-by="getRowAvailability"
      >
        <template class="flex flex-row" slot-scope="{ row }">
          {{ row.data.currentQuantity }} / {{ row.data.minimumQuantity }}
          <el-alert
            v-if="
              row.data.currentQuantity <= row.data.minimumQuantity &&
                row.data.minimumQuantity !== 0
            "
            title="stoc material sub limita"
            type="error"
          ></el-alert>
        </template>
      </ElTableColumn>
      <ElTableColumn label="Comanda">
        <div slot-scope="{ row }">
          <div v-if="row.data.orderedQuantity && row.data.orderedAt">
            <div>{{ row.data.orderedQuantity }}</div>
            <TimeAgo :date="row.data.orderedAt.toDate()" />
            <ElButton
              size="mini"
              icon="el-icon-close"
              @click="receiveCurrentOrder(row)"
              >Receptioneaza</ElButton
            >
          </div>
          <ElButton
            v-else
            size="mini"
            icon="el-icon-plus"
            @click="$refs.materialOrderDialog.add(row.id)"
            >Plaseaza</ElButton
          >
        </div>
      </ElTableColumn>
      <!-- <ElTableColumn label="Categorie">
        <ResourceLoader
          slot-scope="{ row }"
          :path="`materialCategories/${row.data.categoryId}`"
        >
          <ElButton
            slot-scope="{ resource: category }"
            @click="$set(filters, 'category', category.id)"
            size="mini"
            >{{ category.data.name }}</ElButton
          >
        </ResourceLoader>
      </ElTableColumn> -->
      <!-- <ElTableColumn label="Furnizor">
        <ResourceLoader
          slot-scope="{ row }"
          :path="`suppliers/${row.data.supplierId}`"
        >
          <ElButton
            slot-scope="{ resource: supplier }"
            @click="$set(filters, 'supplier', supplier.id)"
            size="mini"
            >{{ supplier.data.name }}</ElButton
          >
        </ResourceLoader>
      </ElTableColumn> -->
      <ElTableColumn align="right" width="50">
        <template slot-scope="{ row }">
          <ElButton
            @click="$refs.materialDialog.edit(row.id)"
            size="mini"
            icon="el-icon-edit"
            circle
          />
        </template>
      </ElTableColumn>
    </ResourceTable>
    <!-- dialogs -->
    <MaterialDialog ref="materialDialog" />
    <MaterialOrderDialog ref="materialOrderDialog" />
  </div>
</template>

<script>
import FiltersMixin from "@/mixins/FiltersMixin"
import MaterialDialog from "@/components/materials/MaterialDialog"
import MaterialFilters from "@/components/materials/MaterialFilters"
import MaterialOrderDialog from "@/components/materials/MaterialOrderDialog"

export default {
  mixins: [FiltersMixin()],
  components: {
    MaterialDialog,
    MaterialFilters,
    MaterialOrderDialog,
  },
  computed: {
    query() {
      return this.$firestore().collection("materials")
    },
  },
  methods: {
    filter(row) {
      const { filters } = this
      if (filters.fsc && row.data.fsc !== true) {
        return false
      }
      if (filters.name) {
        if (row.data.name.match(new RegExp(filters.name, "i")) === null) {
          return false
        }
      }
      if (filters.order && row.data.orderedAt === undefined) {
        return false
      }
      if (filters.category && row.data.categoryId !== filters.category) {
        return false
      }
      if (filters.supplier && row.data.supplierId !== filters.supplier) {
        return false
      }
      if (filters.limit) {
        if (
          row.data.currentQuantity >= row.data.minimumQuantity ||
          row.data.minimumQuantity === 0
        ) {
          return false
        }
      }
      return true
    },
    getRowAvailability(row) {
      const { minimumQuantity, currentQuantity } = row.data
      if (currentQuantity >= minimumQuantity) return 100
      return Math.round((currentQuantity / minimumQuantity) * 100)
    },
    getRowType(row) {
      const { minimumQuantity, currentQuantity } = row.data
      return minimumQuantity <= currentQuantity ? "success" : "danger"
    },
    getRowClassName({ row: material }) {
      const { minimumQuantity, currentQuantity } = material.data
      if (currentQuantity < minimumQuantity) {
        return "bg-red-200"
      }
    },
    receiveCurrentOrder(row) {
      this.$firestore()
        .collection("materials")
        .doc(row.id)
        .update({
          currentQuantity: row.data.currentQuantity + row.data.orderedQuantity,
          orderedQuantity: this.$firestore.FieldValue.delete(),
          orderedAt: this.$firestore.FieldValue.delete(),
          updatedAt: this.$firestore.FieldValue.serverTimestamp(),
        })
    },
  },
}
</script>
